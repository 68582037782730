@import './styles/theme-variables.scss';

/* Layout wrapper styles */
.layout-wrapper {
  @include themify($themes) {
    background: themed('chartBackgroundColor');
  }

  @include themify($themes) {
    color: themed('chartTextColorPrimary');
  }
}

.toggle-btn__input-label {
  @include themify($themes) {
    background-color: themed('toggleBackground');
  }
}

.controls button,
.controls input {
  @include themify($themes) {
    background: themed('chartBackgroundColor');
  }
  @include themify($themes) {
    color: themed('chartTextColorPrimary');
  }
}

.single-line-legend-wrapper {
  @include themify($themes) {
    border: themed('tooltipBorder');
  }
  @include themify($themes) {
    background: themed('chartBackgroundColorOp');
  }
  @include themify($themes) {
    color: themed('chartTextColorPrimary');
  }
}

svg .links-group .link.switch.grey-color {
  @include themify($themes) {
    stroke: themed('SwitchOpenLineColorStroke') !important;
  }
}

svg .nodes-group .node {
  @include themify($themes) {
    stroke: themed('NodeCircleStroke');
  }
}

defs marker {
  fill: white;
  @include themify($themes) {
    stroke: themed('SwitchOpenLineColorStroke');
  }
}

#switch-open line.background {
  @include themify($themes) {
    stroke: themed('chartBackgroundColor');
  }
}
#switch-open line.color {
  @include themify($themes) {
    stroke: themed('SwitchOpenLineColorStroke');
  }
}

svg .nodes-group .node-label,
svg .links-group .node-label {
  @include themify($themes) {
    fill: themed('chartTextColorPrimary');
  }
  @include themify($themes) {
    stroke: themed('chartOutlineColor');
  }
}
