.btn.btn-arrow-block {
  background: transparent;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1;
  color: #000;
  text-shadow: 0 1px 0 #fff;
  opacity: 0.5;
  transition: all 0.3s ease-in-out 0s;
  &:hover {
    opacity: 1;
  }
}

.block-container {
  margin: 10px 0;
  filter: hue-rotate(45deg);

  &.border-success {
    filter: hue-rotate(0deg);
  }
}

.block-icon-delete {
  font-size: 20px;
  position: absolute;
  right: 0px;
  top: 0px;
  opacity: 0.5;
  transition: opacity 0.3s ease-in;

  &:hover {
    opacity: 1;
  }
}
