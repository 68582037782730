.btn.btn-arrow {
  background: transparent;
  color: #ffffff;
  font-size: 18px;
}

.field-text {
  font-size: 20px;
  animation: text-appearance 0.3s ease;
}

.field-container {
  min-height: 130px;
}

@keyframes text-appearance {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
