:root {
  --map-popup-color: #443666;
  --map-active-color: #756793;
  --map-active-color-light: #9287ac;
  --map-outline-color: #c5c3db;
  --map-outline-color-light: #e2e0f0;
  --map-background-color: #b0aece;
  --map-background-hover: #e0deec;
  --map-text-color-primary: #1f2d3d;
  --map-text-color-secondary: #757575;
  --map-text-color-secondary-dark: #616161;
  --map-text-color-secondary-light: #929292;
  --map-text-color-inactive: #bdbdbd;
}

.mapboxgl-ctrl-logo,
.mapboxgl-ctrl-attrib-inner {
  display: block !important;
}

.mapboxgl-ctrl-attrib.mapboxgl-compact {
  background-color: hsla(0, 0%, 100%, 0.5);
  margin: 0;
  padding: 0 5px;
  border-radius: 0;

  button {
    display: none !important;
  }
}

.mapboxgl-ctrl-attrib.mapboxgl-compact button {
  display: none !important;
}

.group-content {
  margin-left: 23px;
}

.filter-values {
  list-style: none;
  width: 100%;
  font-size: 11px;
  padding: 0px;
  margin: 0;

  li {
    padding: 0 0 0 22px !important;
  }

  li {
    position: relative;
    padding: 2px 0 2px 20px;

    i {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}

.legend-filter-circle {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  display: block;
  border-radius: 50%;
  border: 0.5px solid #5e6064;
  height: 18px;
  width: 18px;
  scale: 0.9;

  &:after {
    display: none !important;
  }
}

.legend-filter-icon {
  &:after {
    border: none !important;
    scale: 1.1;
  }
}

.legend-icon {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  scale: 0.9;

  &::after {
    content: '';
    display: block;
    border: 0.5px solid #5e6064;
    border-radius: 4px;
    height: 18px;
    width: 18px;
  }

  &::before {
    font-size: 10px;
    margin-bottom: 1px;
    position: relative;
    top: 1px;
  }
}

.legend-filter {
  display: inline-block;
  border: 0.5px solid #5e6064;
  border-radius: 4px;
  height: 18px;
  width: 18px;
  scale: 0.9;
}

.legend-filter-line {
  border-radius: 4px;
  height: 3px;
  width: 18px;
  scale: 0.9;

  &:after {
    display: none;
  }
}
.car-stats-nk::after {
  background-color: rgba(61, 89, 248, 0.5);
  border-radius: 4px;
}

.island_networks::after {
  background-color: #007bfe;
  border-radius: 50%;
}

.meshed_structure::after {
  background-color: #ffc107;
  border-radius: 50%;
}

.disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

.summary-no-data {
  opacity: 0.6;
  text-align: center;
  font-size: 11px;
  color: var(--map-text-color-secondary);
}

.group-title {
  padding: 15px 18px;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  letter-spacing: 0.02em;
  color: var(--map-text-color-primary);

  &.disabled {
    color: var(--map-text-color-secondary);
    cursor: not-allowed;
  }
}

.group-icon {
  width: 14px;
  height: 14px;
  margin-right: 20px;
  flex-shrink: 0;
}

.filter-group-icon {
  position: relative;
  width: 14px;
  height: 14px;
  margin-right: 15px;
}

.group-title-description {
  display: grid;
  grid-template-rows: 0fr;
  font-size: 10px;
  color: var(--map-text-color-secondary);
  overflow: hidden;
  transition: grid-template-rows 0.2s linear;

  &.open {
    grid-template-rows: 1fr;
  }

  &-inner {
    overflow: hidden;
  }

  .description {
    margin-top: 5px;
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
}

.sidebar-checkbox,
.map-text {
  user-select: none;
  outline: none;
  font-size: 12px;
  font-style: normal;
  color: var(--map-text-color-secondary);
}

.sidebar-checkbox {
  display: inline-flex;
  align-items: center;
  margin: 4px 0;
  cursor: pointer;

  &.disabled {
    cursor: not-allowed;
  }

  &.active {
    color: var(--map-active-color);
  }

  .sidebar-checkbox-icon {
    position: relative;
    overflow: hidden;
    width: 16px;
    height: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
  }

  .sidebar-checkbox-switch {
    position: relative;
    width: 34px;
    height: 18px;
    transition:
      background-color 0.2s linear,
      box-shadow 0.2s linear;
    background: #ebeaee;
    box-shadow: inset 0px 1px 4px #d0d5db;
    border-radius: 100px;
    flex-shrink: 0;

    &::after {
      content: '';
      display: block;
      position: absolute;
      width: 14px;
      height: 14px;
      left: 2px;
      top: 50%;
      transform: translateY(-50%);
      border-radius: 50%;
      background: #ffffff;
      box-shadow: 0px 1px 3px 1px rgb(162 170 179 / 25%);
      transition: left 0.2s linear;
    }
  }

  &.active .sidebar-checkbox-switch::after {
    left: 18px;
  }

  &.active .sidebar-checkbox-switch {
    background: var(--map-active-color);
    box-shadow: inset 0px 1px 4px var(--map-popup-color);
  }

  input {
    display: none;
  }
}

.checkbox-active {
  color: var(--map-active-color);
  font-weight: 600;
}

.checkbox-inactive {
  color: var(--map-text-color-secondary);
}

.checkbox-label {
  font-size: 11px;
  margin-left: 10px;
  font-weight: 400;
  color: rgba(53, 58, 63, 0.8);
}

.map-slider-play {
  font-size: 12px !important;
  margin: 0 14px 0 0 !important;
  padding: 0;
  color: var(--map-background-color) !important;
  background: transparent !important;
  border: none;
  border-radius: 5px;
  transition:
    color 0.3 linear,
    background-color 0.3s linear;

  &:hover {
    color: var(--map-active-color) !important;
  }
}

.map-legend-hint {
  margin-left: 10px;
  font-size: 12px;
  color: var(--map-text-color-secondary);
  cursor: pointer;

  &:hover {
    color: var(--map-active-color);
  }
}

.map-slider-disabled {
  cursor: not-allowed;
}

.map-slider-disabled input {
  pointer-events: none;
}
//
.icon-bolt-blue::after {
  background-image: url('./icons/icon • map layer • circle • bolt • light blue.png');
  background-size: cover;
}
.icon-bolt-gray::after {
  background-image: url('./icons/icon • map layer • circle • bolt • light gray.png');
  background-size: cover;
}
.icon-bolt-purple::after {
  background-image: url('./icons/icon • map layer • circle • bolt • light purple.png');
  background-size: cover;
}
.icon-bolt-red::after {
  background-image: url('./icons/icon • map layer • circle • bolt • light red.png');
  background-size: cover;
}
.icon-bolt-yellow::after {
  background-image: url('./icons/icon • map layer • circle • bolt • light yellow.png');
  background-size: cover;
}
//
.icon-customer-blue::after {
  background-image: url('./icons/icon • map layer • circle • customer • light blue.png');
  background-size: cover;
}
.icon-customer-gray::after {
  background-image: url('./icons/icon • map layer • circle • customer • light gray.png');
  background-size: cover;
}
.icon-customer-purple::after {
  background-image: url('./icons/icon • map layer • circle • customer • light purple.png');
  background-size: cover;
}
.icon-customer-red::after {
  background-image: url('./icons/icon • map layer • circle • customer • light red.png');
  background-size: cover;
}
.icon-customer-yellow::after {
  background-image: url('./icons/icon • map layer • circle • customer • light yellow.png');
  background-size: cover;
}
.icon-customer-new::after {
  background-image: url('./icons/icon • map layer • circle • customer • new.png');
  background-size: cover;
}
//
.icon-light-blue::after {
  background-image: url('./icons/icon • map layer • circle • light • light blue.png');
  background-size: cover;
}
.icon-light-gray::after {
  background-image: url('./icons/icon • map layer • circle • light • light gray.png');
  background-size: cover;
}
.icon-light-purple::after {
  background-image: url('./icons/icon • map layer • circle • light • light purple.png');
  background-size: cover;
}
.icon-light-red::after {
  background-image: url('./icons/icon • map layer • circle • light • light red.png');
  background-size: cover;
}
.icon-light-yellow::after {
  background-image: url('./icons/icon • map layer • circle • light • light yellow.png');
  background-size: cover;
}
//
.icon-link-blue::after {
  background-image: url('./icons/icon • map layer • circle • link • light blue.png');
  background-size: cover;
}
.icon-link-gray::after {
  background-image: url('./icons/icon • map layer • circle • link • light gray.png');
  background-size: cover;
}
.icon-link-purple::after {
  background-image: url('./icons/icon • map layer • circle • link • light purple.png');
  background-size: cover;
}
.icon-link-red::after {
  background-image: url('./icons/icon • map layer • circle • link • light red.png');
  background-size: cover;
}
.icon-link-yellow::after {
  background-image: url('./icons/icon • map layer • circle • link • light yellow.png');
  background-size: cover;
}
//
.icon-investment-3d::after {
  background-image: url('./icons/icon • map layer • investment • purple.png');
  background-size: cover;
}
.icon-flexibility-3d::after {
  background-image: url('./icons/icon • map layer • flexibility • purple.png');
  background-size: cover;
}
//
.icon-customer-battery::after {
  background-image: url('./icons/icon • map layer • DER • battery • purple.png');
  background-size: cover;
}
.icon-customer-electric-vehicle::after {
  background-image: url('./icons/icon • map layer • DER • electric vehicle • purple.png');
  background-size: cover;
}
.icon-customer-heat-pumps::after {
  background-image: url('./icons/icon • map layer • DER • heat pump • purple.png');
  background-size: cover;
}
.icon-customer-other-generation::after {
  background-image: url('./icons/icon • map layer • DER • other generation • purple.png');
  background-size: cover;
}
.icon-customer-solar-panel::after {
  background-image: url('./icons/icon • map layer • DER • solar panel • purple.png');
  background-size: cover;
}
.icon-customer-wind-turbine::after {
  background-image: url('./icons/icon • map layer • DER • wind turbine • purple.png');
  background-size: cover;
}
.icon-customer-electric-vehicle-ai::after {
  background-image: url('./icons/icon • map layer • DER • electric vehicle • green.png');
  background-size: cover;
}
.icon-customer-heat-pumps-ai::after {
  background-image: url('./icons/icon • map layer • DER • heat pump • green.png');
  background-size: cover;
}
.icon-customer-solar-panel-ai::after {
  background-image: url('./icons/icon • map layer • DER • solar panel • green.png');
  background-size: cover;
}

.legend-collapse-group {
  display: grid;
  grid-template-rows: 0fr;
  transition: grid-template-rows 0.2s linear;

  &.open {
    grid-template-rows: 1fr;
  }

  &-inner {
    overflow: hidden;
  }

  & > &-inner > div {
    padding: 0 18px;
  }

  &.nested > &-inner > div > div {
    margin: 0 -20px;
  }

  &.nested > &-inner > div > div > &:last-child &-inner > div,
  &.nested > &-inner > div > div > .info-tab-item:last-child & > &-inner > div,
  &.nested > &-inner > div > div > .info-tab-more-wrapper,
  &:not(.nested):last-child &-inner > div {
    margin-bottom: 20px;
  }

  &.nested > &-inner > div > div > .info-tab-item & > &-inner > div {
    margin-bottom: 0;
  }
}

i.legend-collapse-icon {
  position: relative;
  left: -2px;
}

.legend-collapse-icon {
  transition:
    transform ease-in-out 0.3s,
    color ease-in-out 0.3s;
  color: var(--map-text-color-secondary-light);
  margin-left: 8px;

  div {
    width: 10px;
    height: 10px;
    position: relative;
  }

  div span {
    transition: background-color 0.2s linear;
    position: absolute;
    transition: 0.3s;
    background-color: var(--map-text-color-secondary);
    border-radius: 2px;
  }

  div span:first-child {
    top: 0;
    bottom: 0;
    width: 2px;
    left: 4px;
  }

  div span:last-child {
    left: 0;
    right: 0;
    height: 2px;
    top: 4px;
  }

  &.filter-header {
    transform: rotate(-180deg);
  }

  &:not(.plus).open {
    transform: rotate(-90deg);
    color: var(--map-active-color) !important;
  }

  &.plus.open span {
    transform: rotate(90deg);
    background-color: var(--map-active-color);

    &:last-child {
      left: 50%;
      right: 50%;
    }
  }
}

.legend-collapse-text {
  display: inline-block;
  width: calc(100% - 20px) !important;
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.legend-bounds-icon {
  position: absolute;
  right: 25px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 2px;
  border-radius: 3px;

  &.disabled {
    color: var(--map-text-color-inactive);
    display: none;
  }

  &:not(.disabled):hover {
    color: var(--map-active-color);
  }
}

.legend-bounds-icon-multi {
  position: static;
  transform: none;

  &:not(:only-child) {
    margin-right: 8px;
  }
}

.map-data-quality-btn {
  border: 1px solid var(--map-outline-color) !important;
  border-radius: 8px;
  flex-shrink: 0;

  &,
  &:hover,
  &:focus {
    color: var(--map-active-color) !important;
  }

  &:hover {
    background-color: var(--map-background-hover) !important;
  }

  &:active {
    background-color: var(--map-background-hover) !important;
  }
}
